<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <div class="d-flex justify-content-end">
          <court-info-action />

          <!-- Send to loyal -->
          <b-button
            v-if="client.status_connection === 'RECYCLE'"
            @click="openSendToConnectionModal"
            class="ml-1"
            variant="custom-amg"
          >
            <FeatherIcon icon="LogOutIcon" />
            Return to {{ statusClient }}
          </b-button>
          <!-- Assign advisor -->
          <b-button
            class="ml-1"
            v-if="hasAllAccess"
            @click="openModalAssignAdvisor(false)"
            variant="custom-amg"
          >
            <FeatherIcon icon="UsersIcon" />
            Assign advisor
          </b-button>
          <!-- App password -->
          <b-button
            class="ml-1"
            v-if="hasAllAccess && (client.status != 2 || client.status != 6)"
            @click="appPassKey()"
            variant="custom-amg"
          >
            <b-icon icon="key" />
            APP
          </b-button>
          <!-- Add Service -->

          <b-dropdown
            class="ml-1"
            right
            text="Add Service"
            v-if="validateSendSale || validateChangeService"
            variant="custom-amg"
          >
            <template #button-content>
              <FeatherIcon icon="PlusIcon" />
              Add Services
            </template>
            <!-- <b-dropdown-item @click="openAddService(3)" v-if="validateAddService">
            Add Service
          </b-dropdown-item> -->
            <!-- SendToSales -->

            <!-- <b-dropdown-item
              v-if="validateSendSale"
              v-b-modal.modal-primary
              @click="openDerivationActionModal(client, client)"
            >
              Send to Sales
            </b-dropdown-item> -->
            <!-- SalesProcessTracking -->
            <!-- <b-dropdown-item
            v-b-modal.modal-primary
            @click="openDerivationTrackingModal(data.item)"
          >
            ales process tracking
          </b-dropdown-item> -->
            <b-dropdown-item @click="openAddService(3)">
              Add Service
            </b-dropdown-item>
            <b-dropdown-item
              @click="openAddService(4)"
              v-if="validateChangeService"
            >
              Change Service
            </b-dropdown-item>
          </b-dropdown>
          <!-- Share -->
          <b-button
            v-if="hasAllAccess"
            variant="custom-amg"
            @click="openModalShare"
            class="ml-1"
          >
            <FeatherIcon icon="Share2Icon" />
            Share
          </b-button>
          <!-- Other Programs -->
          <b-button
            :disabled="client != null ? client.count_program == 0 : ''"
            class="ml-1"
            @click="openModalProgramList"
            variant="custom-amg"
          >
            <FeatherIcon icon="MonitorIcon" />
            Others Programs
          </b-button>
          <!-- Charge -->
          <b-button
            class="ml-1"
            variant="custom-amg"
            @click="openChargeModal(null)"
          >
            <feather-icon icon="PlusCircleIcon" size="16" />
            Charge
          </b-button>
          <!-- Send CR -->
          <b-dropdown
            variant="custom-amg"
            class="ml-1 send-cr"
            right
            text="Send CR"
          >
            <template #button-content>
              <feather-icon icon="FileTextIcon" />
              Request CR
              <b-badge
                v-if="countNcrForUpdate > 0"
                v-b-tooltip.hover
                title="The specialist requests a new NCR because it has already expired"
                variant="info"
                >{{ countNcrForUpdate }}</b-badge
              >
            </template>
            <b-dropdown-item @click="openModalCreateNcr('AUTOMATIC')">
              Automatic
            </b-dropdown-item>
            <b-dropdown-item @click="openModalCreateNcr('MANUAL')">
              Manual
            </b-dropdown-item>
          </b-dropdown>
          <!-- New items -->
          <b-button
            class="ml-1"
            variant="custom-amg"
            @click="openModalCreateNewItems()"
          >
            <div class="d-flex">
              <div>New Items</div>
              <div>
                <b-badge
                  v-if="G_COUNTER_NEW_ITEMS > 0"
                  pill
                  variant="danger"
                  class="ml-1"
                >
                  {{ G_COUNTER_NEW_ITEMS }}
                </b-badge>
              </div>
            </div>
          </b-button>
          <!-- Send -->
          <b-dropdown class="ml-1" right variant="custom-amg">
            <template #button-content>
              <FeatherIcon icon="SendIcon" />
              Send
            </template>
            <b-dropdown-item>
              <div class="d-flex justify-content-between align-items-center">
                <span @click="openModalSendSms">Send SMS</span>
                <FeatherIcon
                  icon="MoreVerticalIcon"
                  @click="openModalHistorySms"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item style="cursor: none">
              <div class="d-flex justify-content-between align-items-center">
                <span @click="openModalSendEmail" style="width: 6px"
                  >Send Email</span
                >
                <FeatherIcon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryEmail"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="d-flex justify-content-between align-items-center">
                <span @click="openModalLetter" style="width: 6px"
                  >Send Letter</span
                >
                <FeatherIcon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryLetter"
                />
              </div>
            </b-dropdown-item>

            <b-dropdown-item style="cursor: none">
              <div class="d-flex justify-content-between align-items-center">
                <span @click="openModalSendStatement" style="width: 6px"
                  >STATEMENT</span
                >
                <FeatherIcon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryStatement"
                />
              </div>
            </b-dropdown-item>
            <!-- Insert Here Create WorkPlan Request -->
            <b-dropdown-item>
              <div
                class="d-flex justify-content-between align-items-center"
                @click="createWorkPlanRequest"
              >
                <span style="width: 6px">Request WP</span>
                <FeatherIcon icon="MoreVerticalIcon" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </header-slot>
    <b-card no-body class="m-0">
      <div class="m-3">
        <b-row align-h="between">
          <b-col lg="3">
            <b-skeleton-wrapper :loading="!validateAccount">
              <template #loading>
                <b-card no-body>
                  <b-skeleton width="85%" style="min-width: 124px" />
                  <b-skeleton width="55%" style="min-width: 95px" />
                  <b-skeleton width="70%" style="min-width: 110px" />
                </b-card>
              </template>
            </b-skeleton-wrapper>

            <b-form-group style="margin-bottom: 5px !important">
              <template v-if="validateAccount">
                <span class="font-weight-bold"
                  >START DATE : {{ client.start_date | myGlobal }}</span
                >
                <br />
                <span
                  style="font-weight: bolder; font-size: 17.5px"
                  @click="openEditLead()"
                  class="cursor-pointer"
                  >{{ client.client_name }}
                </span>
                <b-badge
                  v-if="client.client_type_id === 1"
                  class="ml-1"
                  variant="light-primary"
                >
                  Regular
                </b-badge>
                <b-badge
                  v-else-if="client.client_type_id === 2"
                  class="ml-1"
                  variant="light-success"
                >
                  Digital
                </b-badge>
                <span
                  v-if="
                    $store.getters[
                      'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                    ].is_responsible
                  "
                  class="text-primary"
                >
                  <tabler-icon
                    :icon="
                      $store.getters[
                        'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                      ].is_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'
                    "
                    size="15"
                    style="margin-left: 5px"
                  />
                  <strong>CEO's client</strong>
                </span>
                <br />
              </template>
            </b-form-group>

            <b-form-group>
              <template v-if="validateAccount">
                <div style="font-size: 14.5px">
                  <div class="d-flex">
                    <span
                      style="
                        font-weight: bolder;
                        font-size: 14px;
                        margin-right: 5px;
                      "
                    >
                      {{ client.account }}
                    </span>
                    <badge-risky-clients
                      :id-account="client.id"
                      :risk-level="client.risk_level"
                      :risk-description="client.risk_description"
                      :risk-type="client.risk_type"
                    />
                  </div>
                  <template v-if="validateAccount">
                    <StatusClientAccount />
                  </template>
                </div>
              </template>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <EfectivityCard v-if="showEfectivity" :client="client" />
          </b-col>

          <b-col v-if="client.track_finish == null ? false : true" lg="1" />
        </b-row>
        <b-row>
          <b-col
            v-if="client.track_id != null"
            style="margin: 0px; padding: 0px"
            lg="auto"
          >
            <span
              >TRACKING: {{ client.tracking_start }} -
              {{ client.tracking_end }}</span
            >
            <b-row>
              <b-col lg="auto">
                <b-input-group prepend="FILE">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <feather-icon
                        v-if="client.file_status == null"
                        class="text-secondary cursor-pointer"
                        size="21"
                        icon="CheckCircleIcon"
                        @click="checkstatus(client.track_id, 1, 1)"
                      />
                      <feather-icon
                        v-else-if="client.file_status != null"
                        v-b-tooltip.hover.left="client.file_status"
                        class="cursor-pointer text-success"
                        size="21"
                        icon="CheckCircleIcon"
                        @click="checkstatus(client.track_id, 1, 2)"
                      />
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto">
                <b-input-group prepend="CALL">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <div class="text-center">
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          class="cursor-pointer"
                          variant="danger"
                          @click="
                            checkstatus(
                              client.track_id,
                              2,
                              1,
                              client.dates_calls
                            )
                          "
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-badge>
                        <b-badge
                          :id="'call-target-' + client.track_id"
                          variant="primary"
                          style="
                            width: 40px;
                            margin-left: 0.3rem;
                            margin-right: 0.3rem;
                          "
                        >
                          {{ client.call_status }}
                        </b-badge>
                        <b-tooltip
                          v-if="
                            (JSON.parse(client.dates_calls)
                              ? JSON.parse(client.dates_calls).length
                              : 0) > 0
                          "
                          :key="cont"
                          :target="'call-target-' + client.track_id"
                          triggers="hover"
                          placement="left"
                        >
                          <div>
                            <span
                              v-for="(item, i) in JSON.parse(
                                client.dates_calls
                              )"
                              :key="i"
                            >
                              {{ item }}<br />
                            </span>
                          </div>
                        </b-tooltip>
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          class="cursor-pointer"
                          variant="success"
                          @click="
                            checkstatus(
                              client.track_id,
                              2,
                              2,
                              client.dates_calls
                            )
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto">
                <b-input-group prepend="SMS">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <div class="text-center">
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          variant="danger"
                          class="cursor-pointer"
                        >
                          <feather-icon
                            icon="MinusIcon"
                            @click="
                              checkstatus(
                                client.track_id,
                                3,
                                1,
                                client.dates_sms
                              )
                            "
                          />
                        </b-badge>
                        <b-badge
                          :id="'sms-target-' + client.tracking_id"
                          variant="primary"
                          style="
                            width: 40px;
                            margin-left: 0.3rem;
                            margin-right: 0.3rem;
                          "
                        >
                          {{ client.sms_status }}
                        </b-badge>
                        <b-tooltip
                          v-if="
                            (JSON.parse(client.dates_sms)
                              ? JSON.parse(client.dates_sms).length
                              : 0) > 0
                          "
                          :key="cont"
                          :target="'sms-target-' + client.tracking_id"
                          triggers="hover"
                          placement="left"
                        >
                          <div>
                            <span
                              v-for="(item, i) in JSON.parse(client.dates_sms)"
                              :key="i"
                            >
                              {{ item }}<br />
                            </span>
                          </div>
                        </b-tooltip>
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          variant="success"
                          class="cursor-pointer"
                          @click="
                            checkstatus(client.track_id, 3, 2, client.dates_sms)
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto" class="d-flex align-items-center">
                <b-button
                  v-if="client.track_finish == null ? true : false"
                  :disabled="
                    !(
                      client.call_status > 0 &&
                      client.file_status != null &&
                      client.sms_status > 0
                    )
                  "
                  size="sm"
                  variant="primary"
                  @click="finishTracking(client.track_id)"
                >
                  <feather-icon
                    class="mr-1"
                    icon="CheckCircleIcon"
                  />FINISH</b-button
                ></b-col
              >
            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-nav pills class="ml-3 mb-0">
        <b-nav-item
          v-for="(tab, i) in tabs"
          :key="i"
          v-b-tooltip.hover.bottomright="tab.title"
          :to="{ name: tab.routeName }"
          :exact-active-class="tab.extActiveClass"
          :link-classes="['h-full px-3', bgTabsNavs]"
          :active="isActiveDispute(tab.routeName)"
          exact
          >{{ tab.title }}
          <b-badge
            v-if="creditCounter > 0 && tab.title == 'Credit'"
            pill
            variant="danger"
            class="ml-1"
          >
            {{ creditCounter }}
          </b-badge>
          <span
            v-if="countfileclient > 0 && tab.content && tab.title == 'Files'"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 68px;
              margin-top: -2px;
            "
          >
            {{ countfileclient }}
          </span>
          <span
            v-else-if="countdisputeclient > 0 && tab.content"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 68px;
              margin-top: -2px;
            "
          >
            {{ countdisputeclient }}
          </span>
        </b-nav-item>
      </b-nav>
    </b-card>
    <hr class="border-primary border-3 px-0 mt-0" />
    <router-view v-if="showViews" />
    <!-- Header Modals-->
    <modal-share
      v-if="modalShare"
      :modal-share="modalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="modalProgramList"
      :modal-program-list="modalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />

    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      :modul="moduleId"
      @refresh="refresh"
    />

    <modal-create-ncr-request
      v-if="modalCreateNcr"
      :item="ncrParams"
      :ncrType="selectedNcr"
      @close="closeModalCreateNcr"
      @reload="refresh"
    />

    <!-- Send -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="{ id: client.id, name: client.client_name }"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="[{ id: client.id, name: client.client_name }]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <send-letter-modal v-if="modalOpenLetter" @close="closeModalLetter" />

    <modal-history
      v-if="modalHistoryOn"
      :modal-history="modalHistoryOn"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <send-to-call-rounds
      v-if="modalCallRound"
      :modal-call-round="modalCallRound"
      :name-leads="clientsSms"
      :type-call="0"
      @validateStatus="validateStatus"
      @closeModal="closeModalCallRound"
    />
    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />
    <modal-history-email
      v-if="activeModalHistoryEmail"
      :active="activeModalHistoryEmail"
      @close="closeModalHistoryEmail"
    />
    <letter-modal-tracking
      v-if="activeModalHistoryLetter"
      :active="activeModalHistoryLetter"
      @close="closeModalHistoryLetter"
    />

    <modal-send-statement
      v-if="modalSendStatement"
      :modal-status-report="modalSendStatement"
      @closeModalStatus="closeModalSendStatement"
    />

    <modal-history-statement
      v-if="modalHistoryStatement"
      :active="modalHistoryStatement"
      @close="closeModalHistoryStatement"
    />

    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />

    <b-modal
      :title="`App Key for ${client.client_name}`"
      size="sm"
      hide-footer
      title-tag="h4"
      :visible="modalShowAppKey"
      header-class="p-0"
      modal-class="custom-modal-amg"
      header-bg-variant="transparent"
      @hidden="
        modalShowAppKey = false;
        showChangePassword = false;
      "
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            App Key for {{ client.client_name }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="
                modalShowAppKey = false;
                showChangePassword = false;
              "
            />
          </div>
        </div>
      </template>
      <b-row class="mb-2">
        <b-col cols="6" class="d-flex align-items-center">
          <div>Status</div>
          <div class="ml-1">
            <b-badge
              :variant="
                status_in_app == 'Inactive' ? 'light-danger' : 'light-success'
              "
            >
              {{ status_in_app }}
            </b-badge>
          </div>
        </b-col>
        <b-col cols="6" class="d-flex align-items-center clickable">
          <div>Status</div>
          <div class="ml-1">
            <b-badge variant="primary" @click="changePassword(null)">
              Alter password <b-icon icon="key" />
            </b-badge>
          </div>
        </b-col>
      </b-row>
      <hr v-if="showChangePassword" />
      <b-row v-if="showChangePassword">
        <b-col cols="4">New Password </b-col>
        <b-col cols="8" v-if="Boolean(password)" class="text-right text-info">
          Old Password: <strong> {{ password }} </strong>
        </b-col>
        <validation-observer ref="form" class="w-100">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
            class="w-100"
          >
            <b-col cols="12" class="d-flex align-items-center">
              <b-input-group>
                <b-form-input
                  placeholder="New Password..."
                  :state="errors[0] ? false : null"
                  v-model="new_password"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="changePassword(1)">
                    Set Password
                    <feather-icon icon="KeyIcon" class="ml-1" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </validation-provider>
        </validation-observer>
      </b-row>
    </b-modal>
    <ModalAssignAdvisor
      v-if="activeModalAssignAdvisor"
      :active="activeModalAssignAdvisor"
      :is-assignment-required-for-other-process="
        IsAssignmentRequiredForOtherProcess
      "
      @close="closeModalAssignAdvisor($event)"
    />
    <ModalSendClientToConnection
      v-if="activeModalSentToConnection"
      :active="activeModalSentToConnection"
      :params="sendToConnectionParams"
      :clientAccount="client.account"
      :clientName="client.client_name"
      :clientStatus="statusClient"
      @close="closeSentToConnectionModal"
      @refresh="refresh"
    />
    <NewRelationshipModal
      v-if="activeNewRelationshipModal"
      :active="activeNewRelationshipModal"
      :pre-selected-agent-id="preSelectedAgentId"
      @close="closeNewRelationshipModal($event)"
    />
    <derivation-action-modal
      v-if="derivationActionModal"
      :derivation-data="derivationData"
      @send="updateRow"
      @closeModal="closeDerivationActionModal"
    />
    <!--    derivation tracking modal-->
    <derivation-tracking-modal
      v-if="derivationTrackingModal"
      :derivation-data="derivationData"
      @closeModal="closeDerivationTrackingModal"
    />

    <modal-tracking-efectivity
      v-if="modalTrackingEfectivityStatus"
      @closeModal="closeTrackingEfectivityModal"
    ></modal-tracking-efectivity>

    <add-charge-modal
      v-if="modalCharge"
      :title-add-charge="'Add Charge'"
      :edit="null"
      @refresh="refresh"
      @close="closeChargeModal"
    />

    <new-items-modal
      v-if="showModalNewItems"
      :clientData="clientData"
      :editMode="true"
      @close="showModalNewItems = false"
    />
  </div>
</template>
<script>
// Import Vuex
import { mapActions, mapGetters, mapState } from "vuex";

// Import Modals
import ModalShare from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShare";
import ModalAddService from "@/views/commons/components/digital/modals/ModalAddService.vue";
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";

import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail";
import SendToCallRounds from "@/views/credit-experts/views/clients/components/clients/modals/SendToCallRounds.vue";
import SendLetterModal from "@/views/credit-experts/views/clients/dashboard/information-client/modals/SendLetterModal.vue";
import ModalHistorySms from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistorySms.vue";
import ModalHistoryEmail from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryEmail.vue";
import ModalSendStatement from "@/views/commons/components/clients/dashboard/information-client/modals/ModalStatusReport.vue";
import ModalHistoryStatement from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryStatement.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory";
import ModalAssignAdvisor from "./components/ModalAssignAdvisor.vue";
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate";
import ModalSendClientToConnection from "./components/ModalSendClientToConnection.vue";
import NewRelationshipModal from "@/views/ce-digital/sub-modules/settings/views/users-relationship/components/NewRelationshipModal.vue";
import DerivationActionModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationActionModal.vue";
import DerivationTrackingModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationTrackingModal.vue";
import ModalTrackingEfectivity from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/components/ModalTrackingEfectivity.vue";
import EfectivityTooltip from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/components/EfectivityTooltip.vue";
import AddChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue";
import NewItemsModal from "@/views/commons/components/request-ncr/views/modals/NewItemsModal.vue";
// Import Services
import ConnectionClientDashboardService from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/service/dashborard.service";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import workPlanRequestService from "@/views/ce-digital/sub-modules/connection/views/work-plans/work-plans.service";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

//Import data
import TabsInfoConnection from "./data/tabs.data";
import TabsInfoFinancial from "@/views/ce-digital/sub-modules/financial/view/clients/dashboard/tabs/tabs.data";

import moment from "moment";
import EfectivityClient from "@/views/commons/components/clients/components/efectivity/EfectivityClient.vue";

import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";

import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";
import EfectivityCard from "@/views/commons/components/ncr-efectivity/CardNcrEfectivity.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
export default {
  components: {
    ModalShare,
    ModalAddService,
    ModalProgramList,
    ModalSendSms,
    ModalSendEmail,
    SendToCallRounds,
    SendLetterModal,
    ModalHistorySms,
    ModalHistoryEmail,
    ModalSendStatement,
    ModalHistoryStatement,
    ModalHistory,
    LeadUpdate,
    ModalAssignAdvisor,
    ModalSendClientToConnection,
    NewRelationshipModal,
    DerivationActionModal,
    DerivationTrackingModal,
    ModalTrackingEfectivity,
    EfectivityTooltip,
    AddChargeModal,
    EfectivityClient,
    ModalCreateNcrRequest,
    CourtInfoAction,
    StatusClientAccount,
    EfectivityCard,
    NewItemsModal,
    BadgeRiskyClients,
  },
  async created() {
    const { data } = await ClientsOptionsServices.getMissingFiles({
      accountId: this.$route.params.idClient,
    });
    await this.$store.dispatch("ReportStore/A_MISSING_FILES", data);

    await this.A_GET_ALL_SERVICES_COUNTER({
      client_account_id: this.$route.params.idClient,
      module_id: this.moduleId,
    });
    await this.A_COUNT_PENDING_WP_CLIENT({
      client_account_id: this.$route.params.idClient,
    });
    await this.A_GET_COUNTERS_BY_CLIENT(this.$route.params.idClient);
    await this.getUpdates();
    await this.getCountAccountsToRemove();

    // set new items counter
    await this.A_GET_NEW_ITEMS_COUNTER({
      client_account_id: this.$route.params.idClient,
    });
  },
  async mounted() {
    this.addPreloader();
    await this.start();
    this.removePreloader();
  },
  data() {
    return {
      modalListCourtInfo: false,
      showViews: false,
      tabs: [],
      activeNewRelationshipModal: false,
      activeModalHistorySms: false,
      activeModalHistoryEmail: false,
      activeModalHistoryLetter: false,
      modalHistoryOn: false,
      validateStatusCall: false,
      detailsClient: {},
      modalShowAppKey: false,
      showChangePassword: false,
      password: "",
      date_active: "",
      status_in_app: "Inactive",
      new_password: "",
      statusstate: false,
      validateAccount: false,
      validateImg: false,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      modalShare: false,
      modalProgramList: false,
      modalCreateNcr: false,
      modalCreateNcrRealtor: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalOpenLetter: false,
      modalCharge: false,
      modalCallRound: false,
      derivationActionModal: false,
      derivationTrackingModal: null,
      // open modal Add Service
      modalOpenAddService: false,
      event: null,
      detailsClient: {},
      typeModal: null,
      preSelectedAgentId: null,
      // open modal Add Service
      modalSendStatement: false,
      modalHistoryStatement: false,
      activeModalAssignAdvisor: false,
      editSidebar: "first",
      isAddUpdateUserSidebarActive: false,
      typeEdit: "lead",
      cont: 0,
      IsAssignmentRequiredForOtherProcess: false,
      activeModalSentToConnection: false,
      sendToConnectionParams: {},
      modalTrackingEfectivityStatus: false,
      showModalNewItems: false,
      //efectivityClient tooltip
      efectivityClient: {
        positive: 0,
        updated: 0,
        deleted: 0,
        total_accounts: 0,
      },
      selectedNcr: null,
      countNcrForUpdate: null,
      typeAddChange: null,
    };
  },
  methods: {
    openModalListCourtInfo() {
      this.modalListCourtInfo = true;
    },
    closeModalListCourtInfo() {
      this.modalListCourtInfo = false;
    },
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    ...mapActions("CrmLeadStore", [
      "A_GET_LEAD_EDIT",
      "A_GET_STATE_LEADS",
      "A_GET_STATUS_LEADS",
      "A_GET_SOURCE_LEADS",
    ]),
    ...mapActions("CrmGlobalStore", [
      "A_GET_PROGRAMS",
      "A_GET_SOURCE_NAMES",
      "A_GET_STATES",
      "A_GET_EEUU_STATES",
      "A_GET_COUNTRIES",
      "A_GET_OWNERS",
    ]),
    ...mapActions("DebtSolutionClients", [
      "A_GET_CLIENTS",
      "A_COUNT_FILES",
      "A_COUNT_DISPUTE",
    ]),
    ...mapActions({
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
      A_COUNT_PENDING_WP_CLIENT: "clients-store/A_COUNT_PENDING_WP_CLIENT",
      A_GET_NEW_ITEMS_COUNTER: "NewItemsStore/A_GET_NEW_ITEMS_COUNTER",
    }),
    async getCountAccountsToRemove() {
      const params = {
        ncrRequestId: 0,
        idClient: this.$route.params.idClient,
        typeView: "REMOVE",
        leadId: 0,
        toViewRates: false,
      };
      await this.$store.dispatch("AnalysisCrStore/A_COUNT_ANALYSIS_CR", params);
    },
    async getUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    async start() {
      switch (this.$route.matched[0].meta.module) {
        case 20:
          this.tabs = TabsInfoConnection;
          break;
        case 23:
          this.tabs = TabsInfoFinancial;
          break;
        default:
          this.tabs = TabsInfoConnection;
          break;
      }
      await Promise.all([
        this.A_GET_CLIENTS({ id: this.clientAccountId }),
        this.A_COUNT_FILES({ idaccountclient: this.clientAccountId }),
        this.A_COUNT_DISPUTE({ idaccountclient: this.clientAccountId }),
      ]);
      await this.infoLead();
      this.validateImg = true;
      this.validateAccount = true;
      await this.countRequestNcrForUpdate();
      this.showViews = true;
    },
    isActiveDispute(tabName) {
      return this.$route.matched?.[2]?.name == tabName;
    },
    validateStatus() {
      this.validateStatusCall = true;
    },
    async appPassKey() {
      const { data } = await ConnectionClientDashboardService.getInformationApp(
        {
          account_id: this.clientAccountId,
        }
      );
      this.status_in_app = Boolean(data[0].date_activation)
        ? "Active"
        : "Inactive";
      this.date_active = data[0].date_activation;
      this.password = data[0]?.password ?? "";
      this.modalShowAppKey = true;
    },
    async changePassword(type) {
      if (type === 1) {
        const validate = await this.$refs.form.validate();
        if (!validate) return;
        const result = await this.showConfirmSwal();
        const params = {
          account_id: this.clientAccountId,
          password: this.new_password,
        };
        if (result.value) {
          try {
            await ConnectionClientDashboardService.changePassword(params);
            this.password = this.new_password;
            this.new_password = "";
            this.showSuccessSwal();
          } catch (error) {
            this.showErrorSwal(e);
          }
        }
      }
      this.showChangePassword = !this.showChangePassword;
    },
    async createEvent() {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: this.leadId,
      });
      this.event = response.id;
    },
    async openModalCallRound(status) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [
          {
            id: this.client.id,
            name: this.client.client_name,
            account: this.client.accounts,
            nameAccount: this.client.account,
            nameProgram: this.client.program_name,
          },
        ];
        this.modalCallRound = true;
      }
    },
    closeModalCallRound() {
      this.modalCallRound = false;
    },
    modalGeneralService(data, type) {
      this.typeAddChange = type === 4 ? 2 : 1;
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },

    async openAddService(typeModal) {
      const result = await this.showConfirmSwal(
        typeModal === 3
          ? "Are sure want to add new service?"
          : "Are sure want to change service?"
      );
      if (result.value) {
        await this.createEvent();
        let data = {
          lead_name: this.client.client_name,
          lead_id: this.client.lead_id,
          accounts: [
            {
              program: "Credit Experts",
            },
          ],
        };
        this.modalGeneralService(data, typeModal);
      }
    },
    async openEditLead() {
      this.addPreloader();
      await this.getLeadEdit();
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async infoLead() {
      // await all promises
      this.addPreloader();
      await Promise.all([
        this.getLeadEdit(),
        this.getPrograms(),
        this.getOwners(),
        this.getStateLeads(),
        this.getStatusLeads(),
        this.getSourceLeads(),
        this.getSourceNames(),
        this.getStates(),
        this.getEeuuStates(),
        this.getCountries(),
      ]);
      this.removePreloader();
    },
    openModalProgram: async function (data) {
      this.removePreloader();
      if (data === undefined) return;
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.client.id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.isConnectionModule ? 20 : this.client.module,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            let result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({
                  name: "sales-made-connection-client",
                })
                .catch((err) => {
                  this.removePreloader();
                  console.log(err);
                });
            }
          } catch (error) {
            this.removePreloader();
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.leadId });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async refresh() {
      const data = await this.$store.dispatch(
        "DebtSolutionClients/A_GET_CLIENTS",
        {
          id: this.clientAccountId,
        }
      );
    },
    async checkstatus(id, type, status, datos) {
      try {
        if (this.client.track_finish == null) {
          const date = moment().format("MM/DD/YYYY hh:mm A");
          const array = datos == null ? [] : JSON.parse(datos);
          if (status === 2) {
            array.push(date);
          } else {
            array.pop();
          }
          const data =
            await ConnectionClientDashboardService.updateStatusTracking({
              id,
              type,
              status,
              newval: array,
            });
          if (data.status === 200) {
            this.client.file_status = data.data[0].file_status;
            this.client.call_status = data.data[0].call_status;
            this.client.dates_calls = data.data[0].dates_calls;
            this.client.sms_status = data.data[0].sms_status;
            this.client.dates_sms = data.data[0].dates_sms;
          }
        }
      } catch (e) {}
    },
    async finishTracking(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data =
            await ConnectionClientDashboardService.finishTrackingList({
              id,
            });
          if (data.status === 200) {
            this.cont++;
            this.client.track_finish = data.data[0].track_finish;

            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    openSendToConnectionModal() {
      this.sendToConnectionParams = {
        client_account_id: this.clientAccountId,
        client_id: this.clientId,
        in_connection: this.client.in_connection,
        status_connection: this.statusClient.toUpperCase(), //this.client.status_connection,
        reason_connection: "",
        advisor_id: this.client.advisor_id,
        comment: "",
        user_id: this.currentUser.user_id,
      };

      this.activeModalSentToConnection = true;
    },
    closeSentToConnectionModal() {
      this.activeModalSentToConnection = false;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    openModalShare() {
      this.modalShare = true;
    },
    closeModalShare() {
      this.modalShare = false;
    },
    openModalProgramList() {
      this.modalProgramList = true;
    },
    closeModalProgramList() {
      this.modalProgramList = false;
    },
    openChargeModal() {
      this.modalCharge = true;
    },
    closeChargeModal() {
      this.modalCharge = false;
    },
    openModalCreateNcr(type) {
      this.modalCreateNcr = true;
      this.selectedNcr = type;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
      this.countRequestNcrForUpdate();
    },
    openModalCreateNcrRealtor() {
      this.modalCreateNcr = true;
      this.selectedNcr = "MANUAL";
    },
    closeModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = false;
    },
    openCloseModalHistory() {
      this.modalHistoryOn = true;
    },
    closeModalHistory() {
      this.modalHistoryOn = false;
    },
    openModalHistoryStatement() {
      this.modalHistoryStatement = true;
    },
    closeModalHistoryStatement() {
      this.modalHistoryStatement = false;
    },
    openModalSendSms() {
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.$route.params.idClient, type: 0 });
      this.activeModalHistorySms = true;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    async openModalHistoryEmail() {
      await this.getHistoryEmail(this.$route.params.idClient);
      this.activeModalHistoryEmail = true;
    },
    closeModalHistoryEmail() {
      this.activeModalHistoryEmail = false;
    },
    openModalLetter() {
      this.modalOpenLetter = true;
    },
    closeModalLetter() {
      this.modalOpenLetter = false;
    },
    async openModalHistoryLetter() {
      await this.getHistoryLetter(this.$route.params.idClient);
      this.activeModalHistoryLetter = true;
    },
    closeModalHistoryLetter() {
      this.activeModalHistoryLetter = false;
    },
    openModalSendStatement() {
      this.modalSendStatement = true;
    },
    closeModalSendStatement() {
      this.modalSendStatement = false;
    },
    openModalAssignAdvisor(requiredToContinue = false) {
      this.IsAssignmentRequiredForOtherProcess = requiredToContinue;
      this.activeModalAssignAdvisor = true;
    },
    async closeModalAssignAdvisor(
      { success } /* true = Agent assigned successfully */
    ) {
      this.refresh();
      this.IsAssignmentRequiredForOtherProcess = false;
      // if (success) {
      //   this.modalCreateNcr = true;
      // }
      this.activeModalAssignAdvisor = false;
    },
    openModalCreateNewItems() {
      this.clientData = {
        client_name: this.client.client_name,
        client_account: this.client.account,
        client_account_id: this.client.id,
        lead_id: this.client.lead_id,
      };
      this.showModalNewItems = true;
    },
    async createWorkPlanRequest() {
      let confirm = await this.showConfirmSwal(
        "You are about to create a Work Plan Request. Are you sure?"
      );
      if (!confirm.value) return;
      let body = {
        account_id: this.clientAccountId,
      };
      try {
        let data = await workPlanRequestService.findClientAccountData({
          id_account: this.client.account,
        });
        if (data.length != 1) {
          return this.showWarningSwal(
            "Account not found or client has no relationship",
            null
          );
        }
        await workPlanRequestService.createWorkPlanRequest(body);
      } catch (error) {
        const status = error.response.status;
        if (status == 409) {
          return this.showWarningSwal(
            "Already exists request to that account",
            null
          );
        }
        return this.showErrorSwal(error);
      }
      this.showSuccessSwal();
    },
    async closeNewRelationshipModal(success = false) {
      this.activeNewRelationshipModal = false;
      if (success) {
        this.modalCreateNcr = true;
        this.selectedNcr = "AUTOMATIC";
      }
    },
    async countRequestNcrForUpdate() {
      const { data } = await ClientsOptionsServices.countRequestNcrForUpdate({
        client_account_id: this.$route.params.idClient,
      });
      this.countNcrForUpdate = data.data;
    },
    openDerivationActionModal(item, index) {
      item.lead_name = item.client_name;
      item.id = item.lead_id;
      this.derivationData = item;
      this.derivationIndex = index;
      this.derivationActionModal = true;
    },
    closeDerivationActionModal() {
      this.derivationActionModal = false;
    },
    openDerivationTrackingModal(item) {
      this.derivationData = item;
      this.derivationTrackingModal = true;
    },
    closeDerivationTrackingModal() {
      this.derivationTrackingModal = false;
    },
    openTrackingEfectivityModal() {
      this.modalTrackingEfectivityStatus = true;
    },
    closeTrackingEfectivityModal() {
      this.modalTrackingEfectivityStatus = false;
    },
  },
  computed: {
    isPersonalInformation() {
      return this.$route.fullPath.includes("personal-information");
    },
    isDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      missingFiles: "ReportStore/G_MISSING_FILES",
      G_COUNTER_ALL_SERVICES: "clients-store/G_COUNTER_ALL_SERVICES",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
      updates: "ReportStore/G_UPDATES_DISPUTE",
      G_COUNT_ANALYSIS_CR: "AnalysisCrStore/G_COUNT_ANALYSIS_CR",
      G_COUNT_PENDING_WP_CLIENT: "clients-store/G_COUNT_PENDING_WP_CLIENT",
      G_COUNTER_NEW_ITEMS: "NewItemsStore/G_COUNTER_NEW_ITEMS",
      missingFiles: "ReportStore/G_MISSING_FILES",
    }),
    creditCounter() {
      return (
        // this.G_COUNT_ANALYSIS_CR.toRemove +
        this.cPendingNcrByClient +
        this.G_COUNT_PENDING_WP_CLIENT +
        this.missingFiles +
        this.updates +
        this.G_COUNTER_ALL_SERVICES
      );
    },
    clientAccountId() {
      return this.$route.params.idClient;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ncrParams() {
      const {
        id,
        account,
        client_name: client,
        program_id,
        lead_id,
      } = this.client;
      const { user_id, role_id } = this.currentUser;
      return {
        client_account_id: id,
        id, // client_account_id
        account,
        program_id,
        lead_id,
        fullname: client, //full name
        user_id,
        role_id,
        type: "CLIENT",
        used_cards: JSON.stringify([]),
      };
    },
    isConnectionModule() {
      return this.moduleId === 20;
    },
    isCeo() {
      return this.currentUser.role_id === 1;
    },
    isSupervisorOrChief() {
      return this.currentUser.role_id === 2 || this.currentUser.role_id === 17;
    },
    isTeamLeader() {
      return this.currentUser.role_id === 11;
    },
    leadId() {
      return this.client.lead_id;
    },
    clientId() {
      return this.client.client_id;
    },
    hasAllAccess() {
      return this.isCeo || this.isSupervisorOrChief || this.isTeamLeader;
    },
    labelColor() {
      // this chooses the color of the label depending on the connection state in which the client is

      switch (this.client.status_connection) {
        case "HOLD":
          return "light-warning ";
          break;
        case "RECYCLE":
          return "light-danger ";
          break;
        case "LOYAL":
          return "light-primary";
          break;
        default:
          return "";
          break;
      }
    },
    statusClient() {
      switch (this.client.status) {
        case 1:
          return "active";
          break;
        case 2:
          return "hold";
          break;
        case 3:
          return "transition";
          break;
        case 4:
          return "canceled";
          break;
        case 5:
          return "loyal";
          break;
        case 6:
          return "close";
          break;
      }
    },
    efectivity() {
      return this.client.efectivity_percentage ?? 0;
    },
    showEfectivity() {
      return this.client.id != undefined;
    },
    progressVariant() {
      if (this.efectivity >= 40) return "success";
      else return "danger";
    },
    validateSendSale() {
      if (this.isAgent == false) {
        return true;
      } else if (this.isAgent == true && this.efectivity >= 40) {
        return true;
      } else if (
        this.isAgent == true &&
        this.client.status_connection == "HOLD"
      ) {
        return true;
      }
    },
    validateChangeService() {
      if (this.isAgent == false) {
        return true;
      } else if (
        this.isAgent == true &&
        this.efectivity >= 40 &&
        this.client.status_connection != "HOLD"
      ) {
        return true;
      }
    },
    withOrNotResultsLoyalTab() {
      return this.$route.meta.with_results;
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
/* .content-header .content-header-right button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
} */

/* .content-header .content-header-right .b-dropdown button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
} */

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}

.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.font-process {
  font-size: 14px;
}
</style>
<style scoped>
.send-cr >>> .dropdown-toggle {
  padding: 0.6rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 3px;
}
.counter-style {
  width: 21px;
  height: 21px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
  bottom: 10px;
}
</style>
