<template>
  <b-modal
    v-model="isActive"
    @hidden="close"
    centered
    hide-footer
    size="xlg"
    header-class="p-0"
    modal-class="custom-modal-amg"
    header-bg-variant="transparent"
    header-text-variant="primary"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <feather-icon icon="AlignJustifyIcon" class="text-white" size="22" />
          <span> Detail of effectiveness - {{ client.client_name }}</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-row>
      <b-col sm="12">
        <b-nav
          pills
          class="mb-0 border-bottom-primary custom-tab-amg"
          justified
        >
          <b-nav-item
            v-for="(item, index) in listNav"
            exact-active-class="active border-radius-tabs"
            :link-classes="['h-full px-3', bgTabsNavs]"
            exact
            :key="index"
            :active="selectedStatus === item.status"
            @click="selectStatus(item)"
            >{{ item.name }}
            <b-badge v-if="item.count > 0" pill variant="danger">{{
              labelItem(item.count)
            }}</b-badge>
          </b-nav-item>
        </b-nav>

        <b-table
          class="tableEffectivity mt-2 table-new-customization custom-table"
          hover
          :items="items"
          :fields="visibleFields"
          details-td-class="p-0"
          show-empty
          :sticky-header="'45vh'"
          :busy.sync="isBusy"
          outlined
          :tbody-tr-class="isDarkSkin ? 'bg-black' : ''"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty="scope">
            <h4 class="text-center">{{ scope.emptyText }}</h4>
          </template>
          <template #head(transunion)>
            <b-img
              :src="require('@/assets/images/icons/transunion.png')"
              class="custom-img"
              v-b-tooltip.hover.top="'TRANSUNION'"
            />
          </template>
          <template #head(experian)>
            <b-img
              :src="require('@/assets/images/icons/experian.png')"
              class="custom-img"
              v-b-tooltip.hover.top="'EXPERIAN'"
            />
          </template>
          <template #head(equifax)>
            <b-img
              :src="require('@/assets/images/icons/equifax.png')"
              class="custom-img"
              v-b-tooltip.hover.top="'EQUIFAX'"
            />
          </template>
          <template #cell(id)="{ index }">
            <div>
              {{ index + 1 }}
            </div>
          </template>
          <template #cell(transunion)="{ item, field }">
            <div
              class="d-flex justify-content-center"
              v-html="typeClassification(JSON.parse(item.items), field.key)"
            ></div>
          </template>
          <template #cell(experian)="{ item, field }">
            <div
              class="d-flex justify-content-center"
              v-html="typeClassification(JSON.parse(item.items), field.key)"
            ></div>
          </template>
          <template #cell(equifax)="{ item, field }">
            <div
              class="d-flex justify-content-center"
              v-html="typeClassification(JSON.parse(item.items), field.key)"
            ></div>
          </template>
          <template #cell(accounts)="{ item }">
            <div
              class="w-100 d-flex flex-column justify-content-start text-capitalize text-center"
            >
              <div
                class="d-flex flex-column text-center justify-content-center font-size-xmd font-weight-bolder text-truncate"
                style="width: 250px"
                v-b-tooltip:hover
                :title="item.creditor_name"
              >
                {{ item.creditor_name }}
              </div>
              <span
                class="w-100 mt-50 text-capitalize px-1"
                :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                style="font-size: 12px"
              >
                Account #: {{ item.account_number }}
              </span>
            </div>
          </template>
          <template #cell(classification_ncr)="{ item }">
            <div class="d-flex justify-content-center align-items-center h-100">
              <h6
                class="text-uppercase font-weight-bolder text-center"
                :style="`color: ${textColor[item.classification_ncr_id]}`"
              >
                {{ item.classification_ncr }}
              </h6>
            </div>
          </template>
          <template #cell(detail)="{ toggleDetails, detailsShowing }">
            <div @click="toggleDetails">
              <feather-icon
                icon="ChevronDownIcon"
                class="hover-me cursor-pointer"
                size="22"
                :class="{ 'rotate-180': detailsShowing }"
              />
            </div>
          </template>
          <template #row-details="data">
            <transition name="fade">
              <b-table
                thead-class="d-none"
                :items="transformData(JSON.parse(data.item.items))"
                :fields="visibleFields"
                class="tableDetailsEffectivity"
              >
                <template #cell(experian)="{ value }">
                  <ul class="list-unstyled overflow-hidden">
                    <li
                      class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                      v-for="(label, index) in labelsRemove"
                      :key="index"
                    >
                      <div style="width: 50px !important">
                        <span class="text-truncate w-100">
                          <template
                            v-if="
                              [
                                'balance',
                                'monthly_payment',
                                'credit_limit',
                                'h_credit',
                              ].includes(label.key)
                            "
                          >
                            ${{
                              isNaN(value[label.key])
                                ? 0
                                : value[label.key] | currency
                            }}
                          </template>
                          <template v-else>
                            {{
                              value.hasOwnProperty(label.key)
                                ? value[label.key]
                                : "-"
                            }}
                          </template>
                        </span>
                      </div>
                    </li>
                  </ul>
                </template>
                <template #cell(transunion)="{ value }">
                  <ul class="list-unstyled overflow-hidden">
                    <li
                      class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                      v-for="(label, index) in labelsRemove"
                      :key="index"
                    >
                      <div style="width: 50px !important">
                        <span class="text-truncate w-100">
                          <template
                            v-if="
                              [
                                'balance',
                                'monthly_payment',
                                'credit_limit',
                                'h_credit',
                              ].includes(label.key)
                            "
                          >
                            ${{
                              isNaN(value[label.key])
                                ? 0
                                : value[label.key] | currency
                            }}
                          </template>
                          <template v-else>
                            {{
                              value.hasOwnProperty(label.key)
                                ? value[label.key]
                                : "-"
                            }}
                          </template>
                        </span>
                      </div>
                    </li>
                  </ul>
                </template>
                <template #cell(equifax)="{ value }">
                  <ul class="list-unstyled overflow-hidden">
                    <li
                      class="padding w-100 d-flex justify-content-center align-items-center justify-content-center text-center text-capitalize"
                      v-for="(label, index) in labelsRemove"
                      :key="index"
                    >
                      <div style="width: 50px !important" class="text-center">
                        <span class="text-truncate w-100">
                          <template
                            v-if="
                              [
                                'balance',
                                'monthly_payment',
                                'credit_limit',
                                'h_credit',
                              ].includes(label.key)
                            "
                          >
                            ${{
                              isNaN(value[label.key])
                                ? 0
                                : value[label.key] | currency
                            }}
                          </template>
                          <template v-else>
                            {{
                              value.hasOwnProperty(label.key)
                                ? value[label.key]
                                : "-"
                            }}
                          </template>
                        </span>
                      </div>
                    </li>
                  </ul>
                </template>
                <template #cell(accounts)>
                  <ul class="list-unstyled overflow-hidden">
                    <li
                      class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                      v-for="(data, index) in labelsRemove"
                      :key="index"
                    >
                      <div style="width: 50px !important">
                        <span class="text-truncate w-100">{{ data.text }}</span>
                      </div>
                    </li>
                  </ul>
                </template>
                <template #cell(classification_ncr)>
                  <ul class="list-unstyled overflow-hidden">
                    <li
                      class="padding w-100 d-flex justify-content-center text-center text-capitalize"
                      v-for="(data, index) in labelsRemove"
                      :key="index"
                    >
                      <div style="width: 50px !important">
                        <span class="text-truncate w-100">&nbsp;</span>
                      </div>
                    </li>
                  </ul>
                </template>
                <template #cell()>
                  <ul class="list-unstyled overflow-hidden">
                    <li
                      class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                      v-for="(data, index) in labelsRemove"
                      :key="index"
                    >
                      <div style="width: 50px !important">
                        <span class="text-truncate w-100">&nbsp;</span>
                      </div>
                    </li>
                  </ul>
                </template>
              </b-table>
            </transition>
          </template>
        </b-table>
      </b-col>
      <b-col sm="12">
        <e-charts :options="option" autoresize style="width: 100%" />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
//Import Fields
import fieldsDataModalTracking from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/data/fields.data.ModalTrackingEfectivity.js";
import LabelsRemove from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/data/label-bureau";
//Import Service
import ConnectionClientDashboardService from "../service/dashborard.service";
//Import library Dashboard
import ECharts from "vue-echarts";
import echarts from "echarts/lib/echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/title";
export default {
  components: { ECharts },
  props: {
    client: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      labelsRemove: LabelsRemove,
      isActive: true,
      fields: fieldsDataModalTracking.creditExpertsAndBoostCredit,
      items: [],
      isBusy: true,
      option: {
        grid: {
          show: true,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false,
        },
        title: {
          left: "center",
          top: "top",
          text: "Effectiveness graph",
          show: true,
        },

        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,

          axisLabel: {
            color: "#FFFFFF",
            margin: 20,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#dddddd",
              width: 1,
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
            color: "#ffff",
            fontSize: 15,
          },
          nameTextStyle: {
            color: "#616161",
          },
          axisLine: {
            show: true,
            onZero: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#616161",
              type: "dashed",
              width: 1.3,
              interval: 10,
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            name: "Effectivity",
            type: "line",
            data: [],

            symbolSize: 20,
            label: {
              show: true,
              position: "top",
              distance: 10,
              fontWeight: "bolder",
              color: "rgb(255, 255, 255)",
              fontSize: 15,
              backgroundColor: "rgb(22, 84, 204)",
              borderRadius: [10, 10, 10, 10],
              padding: [12, 12, 12, 12],
              shadowColor: "rgba(0, 144, 231, 1)",
              shadowBlur: 12,
              shadowOffsetX: 5.5,
              formatter: function (params) {
                return params.value + " %";
              },
            },
            lineStyle: {
              width: 4,
              color: "#3F7AFA",
              shadowColor: "rgba(0,0,0,0.3)",
              shadowBlur: 10,
              shadowOffsetY: 8,
            },

            itemStyle: {
              color: "rgba(54, 162, 235, 1)",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(0, 144, 231, 0.8)",
                },
                {
                  offset: 1,
                  color: "rgba(0, 144, 231, 0.28)",
                },
              ]),
            },

            markPoint: {
              data: [{ type: "max", name: "Max" }],
            },
          },
        ],
      },
      listNav: [
        {
          name: "DELETED",
          count: 0,
          status: 4,
        },
        {
          name: "UPDATED",
          count: 0,
          status: 3,
        },
        {
          name: "DISPUTE",
          count: 0,
          status: 1,
        },
        {
          name: "NEGATIVE",
          count: 0,
          status: 6,
        },
        {
          name: "VERIFIED",
          count: 0,
          status: 2,
        },
      ],
      selectedStatus: 4,
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      keyTable: 0,
    };
  },
  async created() {
    await this.getEffectivityClientTracking();
    await this.getTableEffectivity();
    await this.getCountEffectivity();
    this.option.xAxis = {
      axisLabel: { color: this.isDarkSkin ? "#fff" : "#000" },
    };
    this.option.yAxis = {
      axisLabel: { color: this.isDarkSkin ? "#fff" : "#000" },
    };
    this.option.legend = {
      textStyle: { color: this.isDarkSkin ? "#fff" : "#000" },
    };
    this.option.title = {
      textStyle: { color: this.isDarkSkin ? "#fff" : "#000" },
    };
  },
  methods: {
    close(success = false) {
      this.$emit("closeModal", success);
    },
    async getEffectivityClientTracking() {
      try {
        const { data } =
          await ConnectionClientDashboardService.getEfectivityTracking({
            account_id: this.clientAccountId,
            lead_id: this.client.lead_id
          });
        if (data) {
          let item = data.data;
          this.option.series[0].data = item.map((item) =>
            Math.floor(item.efectivity_percentage)
          );
          this.option.xAxis.data = item.map((item) =>
            this.$options.filters.myGlobal(item.created_date)
          );
          this.option.series[0].symbol =
            "image://" + require("@/assets/images/icons/IconBall.png");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getTableEffectivity() {
      try {
        const { data } =
          await ConnectionClientDashboardService.getTableEffectivity({
            p_client_account_id: this.clientAccountId,
            p_status: this.selectedStatus,
          });
        data.data.forEach((element) => {
          element.showDetails = false;
        });

        this.items = data.data;
        this.isBusy = false;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getCountEffectivity() {
      try {
        const { data } =
          await ConnectionClientDashboardService.getCountEffectivity({
            client_account_id: this.clientAccountId,
            lead_id: this.client.lead_id
          });
        const newData = data.data;
        this.listNav.forEach((navItem) => {
          if (newData.hasOwnProperty(navItem.name.toLowerCase())) {
            navItem.count = parseInt(newData[navItem.name.toLowerCase()]);
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    transformData(data) {
      const transformedData = {
        experian: {},
        equifax: {},
        transunion: {},
      };

      data.forEach((obj) => {
        const bureau = this.getBureau(obj.bureau_id);
        this.$set(transformedData, bureau, obj);
      });
      return [transformedData];
    },
    getBureau(bureauId) {
      switch (bureauId) {
        case 1:
          return "transunion";
        case 2:
          return "experian";
        case 3:
          return "equifax";
        default:
          return "";
      }
    },
    async selectStatus(item) {
      this.selectedStatus = item.status;
      this.isBusy = true;
      await this.getTableEffectivity();
      this.isBusy = false;
    },
    labelItem(count) {
      return count == 1 ? `${count} item` : `${count} items`;
    },
    typeClassification(item, key) {
      let filter = item.filter((item) => item.bureau_name == key);
      let background = {
        MEDIUM: "rgb(255, 175, 0)",
        LOW: "rgb(254, 0, 0)",
        HIGH: "rgb(0, 243, 128)",
        null: "#82868b",
      };
      const imagePath = require(`@/assets/images/icons/${key}.png`);
      return filter.length > 0
        ? `<span class="custom-badge-effectivity" style="background:${
            background[filter[0].type_classification]
          }">${filter[0].type_classification || "NOT QUALIFIED"}</span>`
        : `<img src="${imagePath}" width="30px" height="30px" class="custom-bg-logo-effectivity"/>`;
    },
    setInitialOption() {},
  },
  computed: {
    clientAccountId() {
      return this.$route.params.idClient;
    },
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
  },
  watch: {
    isDarkSkin(value) {
      if (value) {
        this.option.xAxis = { axisLabel: { color: "#fff" } };
        this.option.yAxis = { axisLabel: { color: "#fff" } };
        this.option.legend = { textStyle: { color: "#fff" } };
        this.option.title = { textStyle: { color: "#fff" } };
      } else {
        this.option.xAxis = { axisLabel: { color: "#000" } };
        this.option.yAxis = { axisLabel: { color: "#000" } };
        this.option.legend = { textStyle: { color: "#000" } };
        this.option.title = { textStyle: { color: "#000" } };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;
  border-radius: 1.5rem 1.5rem 0px 0px;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.tableEffectivity::v-deep thead tr th {
  background-color: #3a72ea !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.tableEffectivity::v-deep tbody .bg-black {
  background: #131313 !important;
}
.tableDetailsEffectivity::v-deep tbody tr td {
  padding: 0 !important;
  // background-color: rgba(22, 84, 204, 1) !important;
}
.custom-img {
  filter: brightness(0) invert(1);
  height: 20px !important;
  width: 20px !important;
}
li.padding {
  height: 35px;
  border-top: 1px solid #a7a9ad !important;
}
</style>
<style>
.custom-badge-effectivity {
  display: inline-block;
  padding: 0.5em 0.9em;
  font-size: 90%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.4rem;
  color: #fff;
}
.custom-bg-logo-effectivity {
  filter: grayscale(90%) !important;
}
</style>
