<template>
  <b-card
    :class="{
      'bg-dark': isDarkSkin,
      'bg-light': !isDarkSkin,
    }"
  >
    <div class="d-flex justify-content-between">
      <p class="m-0" style="font-weight: bolder; font-size: 14px">
        Removed items:
        {{ parseInt(efectivity.deleted) + parseInt(efectivity.updated) }}/{{
          total_accounts
        }}
      </p>
      <feather-icon
        icon="InfoIcon"
        @click="$refs.efectivityModal.openTrackingEfectivityModal()"
        class="cursor-pointer"
      />
    </div>
    <div class="" style="margin-top: 5px">
      <efectivity-client ref="efectivityModal" :client="client" />
    </div>
  </b-card>
</template>
<script>
import EfectivityClient from "@/views/commons/components/clients/components/efectivity/EfectivityClient.vue";
import EfectivityServices from "@/views/commons/components/ncr-efectivity/efectivity.service.js";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    EfectivityClient,
  },
  props: {
    client: {
      type: [Object, Array],
      required: true,
    },
  },
  async created() {
    await this.getCountEffectivity();
  },
  data() {
    return {
      efectivity: {
        deleted: 0,
        dispute: 0,
        updated: 0,
      },
      total_accounts: 0,
    };
  },
  watch: {
    "client.efectivity_percentage": {
      handler() {
        this.getCountEffectivity();
      },
    },
    async getEfectivity(state) {
      if (state) {
        await this.getCountEffectivity();
        this.updateEfectivity(false);
      }
    },
  },
  methods: {
    ...mapActions({
      updateEfectivity: "ReportStore/A_REFRESH_EFECTIVITY",
    }),
    async getCountEffectivity() {
      try {
        const { data } = await EfectivityServices.getCountEffectivity({
          client_account_id: this.client.id,
        });
        if (data.data) {
          this.efectivity.deleted = data.data?.deleted_accounts ?? 0;
          this.efectivity.dispute = data.data?.accounts_disputed ?? 0;
          this.efectivity.updated = data.data?.updated_accounts ?? 0;
          this.total_accounts = [2, 3].includes(this.client.program_id)
            ? data.data?.total_accounts ?? 0
            : data.data?.total_accounts - data.data?.positive_accounts;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getEfectivity: "ReportStore/G_REFRESH_EFECTIVITY",
    }),
  },
};
</script>
