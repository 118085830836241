<template>
    <b-modal v-model="isActive" @hidden="close" hide-footer centered no-close-on-backdrop >
       <b-row>
            <b-col>
                <ClientAccountHeader :account="accountClient" :client="nameClient" />
            </b-col>
       </b-row>
       <b-row>
            <b-col>
                <label> Advisors </label>
                <v-select v-model="advisor" :class="{'border-danger': !hasAdvisor && Boolean(count)}" :options="advisors" :reduce="advisor => advisor.id" label="user" />
            </b-col>
       </b-row>
       <b-row v-if="IsAssignmentRequiredForOtherProcess">
        <b-col>
            <div class="text-center mt-1">
                <span class="text-danger">
                    <small>
                        This client doesn't have an agent assigned, please set one before continue.
                    </small>
                </span>
            </div>
        </b-col>
    </b-row>
    <b-row class="my-2">
         <b-col>
             <center>
                 <b-button variant="success" @click="assignAdvisor">
                     Assign
                 </b-button>
             </center>
         </b-col>
    </b-row>
</b-modal>
</template>
<script>
// Import Components
import ClientAccountHeader from './ClientAccountHeader.vue'

// Import Vuex
import { mapGetters } from 'vuex'

// Import Service
import ConnectionClientDashboardService from '../service/dashborard.service'

export default {
    components:{
        ClientAccountHeader
    },
    props:{
        active: {
            type: Boolean,
            required: true,
        },
        IsAssignmentRequiredForOtherProcess: {
            type: Boolean,
            default: false
        }
    },
    async created(){
        await this.start()
        this.isActive = this.active
    },
    data(){return{
        isActive:false,
        advisor: null,
        advisors:[],
        count: 0, //counter for intent assign without advisor selected

    }},
    methods:{
        async start(){
            this.advisors = await ConnectionClientDashboardService.getAdvisorsFromConnection()
        },
        async assignAdvisor(){
            if(!this.hasAdvisor){
                this.count++
                return 
            }

            const params = {
                client_account_id : this.clientAccountId,
                advisor_id: this.advisor,
                user_id: this.userId
            }
            const {success,msg} = await ConnectionClientDashboardService.assignAdvisor(params)

            if(success){
                this.showSuccessSwal(msg,'Sucess!')
            }
            else{
                this.showToast("danger", "top-right", "System Error", "XIcon", msg)
            }

            this.close( true )

        },
        close(success = false){
            this.$emit('close', success)
        }
    },
    computed:{
        ...mapGetters({
            client: "DebtSolutionClients/G_CLIENTS",
            currentUser: "auth/currentUser"
        }),
        clientAccountId(){
            return this.client.id
        },
        nameClient(){
            return this.client.client_name
        },
        accountClient(){
            return this.client.account
        },
        userId(){
            return this.currentUser.user_id
        },
        hasAdvisor(){
            return !!this.advisor
        }
    }
}
</script>