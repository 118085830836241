<template>
    <b-modal v-model="isActive" @hidden="close" :title="title" title-tag="h3" hide-footer centered>
        <b-container>
            <b-row>
                <b-col>
                    <ClientAccountHeader 
                        :client="clientName"
                        :account="clientAccount"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Commentay">

                        <b-textarea rows="2" v-model="comment" :class="{'border-danger':(validator && !hasData)}"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="my-2">
                <b-col>
                    <center>
                        <b-button variant="success" @click="sendClient">
                            SEND
                        </b-button>
                    </center>
                </b-col>
            </b-row>
        </b-container>
        

    </b-modal>
</template>
<script>
// Import Service
import ConnectionClientDashboardService from '../service/dashborard.service'
// Import Components
import ClientAccountHeader from './ClientAccountHeader.vue'

export default {
    components:{
        ClientAccountHeader
    },
    props:{
        active:{
            type: Boolean,
            required: true
        },
        params: {
            type: Object,
            required: true
        },
        clientName:{
            type: String,
            required: true
        },
        clientAccount:{
            type: String,
            required: true
        },
        clientStatus:{
            type: String,
            required: true
        }

    },
    mounted(){
        this.isActive = this.active
    },
    data(){return {
        isActive: false,
        comment: '',
        hasData:false,
        validator:false
    }},
    methods:{
        async sendClient(){

            this.hasData = Boolean(this.comment.length)
            this.validator = true
            
        if(this.hasData && this.validator){
            
            const { isConfirmed } = await this.showConfirmSwal()

            if(isConfirmed){
                
                this.addPreloader()

                this.params.comment = this.comment

                const data = await ConnectionClientDashboardService.sendClientToConnection(this.params)


                if(data[1] === 200){
                    this.$emit('refresh')
                    this.showSuccessSwal()
                    this.close()
                }else{
                    this.showErrorSwal(`${data[1]} ${data[0]}`)
                }   
                    this.removePreloader()

                }         
            }
            return
            
        },
        close(){
            this.$emit('close')
        }
    },
    computed:{
        title(){
            return `Sent to ${this.clientStatus}`
        }
    },
}
</script>






