<template>
    <b-modal
    v-model="isActive"
    @hidden="close"
    centered
    title="TRACKING LIST"
    hide-footer
    size="md"
    >
        <b-row>
            <b-col>
                <b-table
                small
                slot="table"
                :items="items"
                :fields="fields"
                responsive="sm"
                :busy.sync="isBusy"
                >
                    <template #table-busy>
                        <div class="text-center my-2" >
                            <b-spinner class="align-middle" variant="primary" />
                            <strong class="ml-1 text-primary">Loading...</strong>
                        </div>
                    </template>

                    <template #cell(first_name)="item">
						{{ item.item.first_name }} {{ item.item.last_name }}
					</template>

                    <template #cell(efectivity_percentage)="item">
						{{ item.item.efectivity_percentage }}%
					</template>

                    <template #cell(created_at)="data">
						{{ data.item.created_at | myGlobal }}
					</template>
                </b-table>
            </b-col>
        </b-row>

        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    class="float-right"
                    @click="close"
                >
                    Close
                </b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
//Import Fields
import fieldsDataModalTracking from '@/views/ce-digital/sub-modules/connection/views/clients/dashboard/data/fields.data.ModalTrackingEfectivity.js';

//Import Service
import ConnectionClientDashboardService from "../service/dashborard.service";
export default {
    components:{
    },
    props:{
    },
    data(){
        return{
            isActive:true,
            fields: fieldsDataModalTracking.otherPrograms,
            items:[],
            isBusy:false,
        }
    },
    created(){
        this.getEfectivityClientTracking();
    },
    methods:{
        close(success = false){
            this.$emit('closeModal', success)
        },
        async getEfectivityClientTracking(){
            this.isBusy=true;
            const { data } = await ConnectionClientDashboardService.getEfectivityTracking(
            {account_id: this.clientAccountId,}
            );
            if(data){
                this.isBusy=false;
                this.items=data.data;
            }
        },
    },
    computed:{
        clientAccountId() {
            return this.$route.params.idClient;
        },
    }
}
</script>
