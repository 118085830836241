<template>
  <div>
    <div id="tooltip-target-1">
      <b-progress
        class="w-100 cursor-pointer"
        :max="100"
        :class="`bg-light-${progressVariant}`"
        style="font-size: 15px; font-weight: bold"
        height="1.5rem"
        @click.native="openTrackingEfectivityModal"
      >
        <b-progress-bar
          :value="efectivity"
          :variant="progressVariant"
          :label="`${parseInt(efectivity)} %`"
          show-progress
          show-value
        />
      </b-progress>
    </div>

    <ModalTrackingEfectivityCeDs
      v-if="modalTrackingEfectivityStatusCeDs"
      @closeModal="closeTrackingEfectivityModal"
      :client="client"
    />
    <ModalTrackingEfectivityOtherPrograms
      v-if="modalTrackingEfectivityStatus"
      @closeModal="closeTrackingEfectivityModal"
      :client="client"
    />
  </div>
</template>

<script>
import EffectivityTooltip from "@/views/commons/components/clients/components/efectivity/EfectivityTooltip.vue";
import ConnectionClientDashboardService from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/service/dashborard.service.js";
import ModalTrackingEfectivityCeDs from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/components/ModalTrackingEfectivityCopy.vue";
import ModalTrackingEfectivityOtherPrograms from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/components/ModalTrackingEfectivity.vue";

import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    EffectivityTooltip,
    ModalTrackingEfectivityCeDs,
    ModalTrackingEfectivityOtherPrograms,
  },
  props: {
    client: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      efectivityClient: {
        positive: 0,
        updated: 0,
        deleted: 0,
        total_accounts: 0,
      },
      modalTrackingEfectivityStatus: false,
      modalTrackingEfectivityStatusCeDs: false,
    };
  },
  computed: {
    ...mapGetters({
      getEfectivity: "ReportStore/G_REFRESH_EFECTIVITY",
    }),
    efectivity() {
      return this.client.efectivity_percentage ?? 0;
    },
    progressVariant() {
      if (this.efectivity >= 40) return "success";
      else return "warning";
    },
  },
  methods: {
    ...mapActions({
      updateEfectivity: "ReportStore/A_REFRESH_EFECTIVITY",
    }),
    async getEfectivityClient() {
      const { data, status } =
        await ConnectionClientDashboardService.getEfectivity({
          account_id: this.client.id,
        });
      if (status == 200 && Boolean(data.data)) {
        this.efectivityClient.positive = data.data.accounts_positive;
        this.efectivityClient.deleted = data.data.accounts_deleted;
        this.efectivityClient.updated = data.data.accounts_updated;
        this.efectivityClient.total_accounts = data.data.total_accounts;
      } else {
        this.efectivityClient.positive = 0;
        this.efectivityClient.deleted = 0;
        this.efectivityClient.updated = 0;
        this.efectivityClient.total_accounts = 0;
      }
    },
    efectivityPercentage(efectivityType, total_accounts) {
      return parseInt((efectivityType * 100) / total_accounts) + "%";
    },
    openTrackingEfectivityModal() {
      if ([2, 3].includes(this.client.program_id)) {
        this.modalTrackingEfectivityStatusCeDs = true;
      } else {
        this.modalTrackingEfectivityStatus = true;
      }
    },
    closeTrackingEfectivityModal() {
      if ([2, 3, 7].includes(this.client.program_id)) {
        this.modalTrackingEfectivityStatusCeDs = false;
      } else {
        this.modalTrackingEfectivityStatus = false;
      }
    },
  },
  mounted() {
    this.getEfectivityClient();
  },
  watch: {
    "client.id"() {
      this.getEfectivityClient();
    },
    async getEfectivity(state) {
      if (state) {
        await this.getEfectivityClient();
        this.updateEfectivity(false);
      }
    },
  },
};
</script>

<style></style>
