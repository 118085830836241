<template>
    <div>
        <table style="table-layout: fixed;">
            <tr v-for="(item, index) in efectivityClient" :key="index">
                <td colspan="3" v-if=" index=='positive' || index=='updated' || index=='deleted' || index=='total_accounts' ">
                    <tr>
                        <td style="width:5rem;">
                            <span class="title_report"> {{ index == 'total_accounts' ? 'Total' : index }}</span>
                        </td>
                        <td style="width:8rem;">
                            <b-progress 
                            v-if="index != 'total_accounts'"
                            :class="`bg-light-${index=='positive' ? 'primary' : index=='deleted' ? 'danger' : index=='updated' ? 'success' : 'secondary' }`"
                            
                            height="12px" 
                            >
                                <b-progress-bar
                                :value="(item * 100) / efectivityClient.total_accounts"
                                :variant=" index=='positive' ? 'primary' : index=='deleted' ? 'danger' : index=='updated' ? 'success' : '' "
                                :label="efectivityPercentage(item)"
                                max="100"
                                show-progress
                                show-value
                                animated
                                style="font-size:11px"
                                />
                            </b-progress>
                        </td>
                        <td><span class="num_report">{{ item }}</span></td>
                    </tr>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: 'EfectivityTooltip',
    props: {
        efectivityClient: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {}
    },
    methods: {
        efectivityPercentage(efectivityType){
            return  parseInt((efectivityType * 100) / this.efectivityClient.total_accounts)+'%';
        },
        efectivityNum(efectivityType){
            return  parseInt((efectivityType * 100) / this.efectivityClient.total_accounts);
        },
    },
    computed:{   
        clientAccountId() {
            return this.$route.params.idClient;
        },
    }    
}
</script>
<style>
/*::tooltip::*/
.title_report{
  display:block;
  height:21px;
  padding:0 3px;
  text-align:left;
  text-transform: capitalize;
}
.num_report{
  display:block;
  height:auto;
  min-width:30px;
}
</style>