var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticStyle:{"table-layout":"fixed"}},_vm._l((_vm.efectivityClient),function(item,index){return _c('tr',{key:index},[(
          index == 'positive' ||
          index == 'updated' ||
          index == 'deleted' ||
          index == 'total_accounts'
        )?_c('td',{attrs:{"colspan":"3"}},[_c('tr',[_c('td',{staticStyle:{"width":"5rem"}},[_c('span',{staticClass:"title_report"},[_vm._v(" "+_vm._s(index == "total_accounts" ? "Total" : index))])]),_c('td',{staticStyle:{"width":"8rem"}},[(index != 'total_accounts')?_c('b-progress',{class:("bg-light-" + (index == 'positive'
                  ? 'primary'
                  : index == 'deleted'
                  ? 'danger'
                  : index == 'updated'
                  ? 'success'
                  : 'secondary')),attrs:{"height":"12px"}},[_c('b-progress-bar',{staticStyle:{"font-size":"11px"},attrs:{"value":(item * 100) / _vm.efectivityClient.total_accounts,"variant":index == 'positive'
                    ? 'primary'
                    : index == 'deleted'
                    ? 'danger'
                    : index == 'updated'
                    ? 'success'
                    : '',"label":_vm.efectivityPercentage(item),"max":"100","show-progress":"","show-value":"","animated":""}})],1):_vm._e()],1),_c('td',[_c('span',{staticClass:"num_report"},[_vm._v(_vm._s(item))])])])]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }