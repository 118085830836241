<template>
  <div>
    <b-modal
      v-model="modalCall"
      modal
      title="TRACKING"
      size="xmd"
      modal-class="custom-modal-amg"
      scrollable
      header-bg-variant="transparent"
      header-class="p-0"
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModal"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            Tracking
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModal"
            />
          </div>
        </div>
      </template>
      <ValidationObserver ref="form">
        <b-form class="p-1">
          <!-- Title Clients -->
          <b-row cols="1">
            <b-input-group prepend="Clients">
              <div
                class="
                  form-control
                  h-auto
                  bg-transparent
                  border-secondary
                  sms-container
                "
              >
                <template v-for="(item, index) in nameLeads">
                  <span :key="index">
                    <span>{{ item.name }}</span>
                    <feather-icon
                      v-if="nameLeads.length > 1"
                      class="text-danger cursor-pointer"
                      icon="MinusCircleIcon"
                      @click="deleteAccount(item.id)"
                    />&nbsp;
                  </span>
                </template>
              </div>
            </b-input-group>
          </b-row>
          <b-row cols="1">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group label="Task" :state="errors[0] ? false : null">
                <v-select
                  v-model="task"
                  :options="taskData"
                  label="text"
                  :reduce="(task) => task.value"
                  :selectable="(task) => task.value != ''"
                />
              </b-form-group>
            </ValidationProvider>
          </b-row>
          <b-row cols="1">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group label-for="message" label="Description">
                <b-form-textarea
                  id="message"
                  v-model="description"
                  placeholder="Write new message"
                  rows="10"
                  maxlength="1000"
                  :state="errors[0] ? false : null"
                />
                <template #description>
                  <small tabindex="-1" class="form-text text-danger"
                    >Max: 1000 characters</small
                  >
                </template>
              </b-form-group>
            </ValidationProvider>
          </b-row>
          <b-row cols="1">
            <b-form-group label="File">
              <b-form-file
                placeholder="Select a file"
                @change="onImageChange"
              />
              <!-- <drag-and-drop :key="idDrag"
                         v-model="files"
                         :files-array="files"/>-->
              <small tabindex="-1" class="form-text text-danger"
                >Upload Files Max 8MB</small
              >
            </b-form-group>
          </b-row>
        </b-form>
      </ValidationObserver>
      <template #modal-footer>
        <div class="d-flex justify-content-end mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="sendCallRounds"
          >
            <template v-if="isLoading">
              <b-spinner small />
              <span>Loading...</span>
            </template>
            <span v-else>Send</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ClientService from "@/views/credit-experts/services/clients.service.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    modalCallRounds: {
      type: Boolean,
    },
    nameLeads: {
      type: Array,
    },
    typeCall: {
      type: [String, Number],
    },
  },
  data() {
    return {
      description: "",
      task: "",
      taskData: [
        {
          value: "",
          text: "Select task",
          selected: true,
          disabled: true,
        },
        { value: "TRANSFER", text: "TRANSFER" },
        { value: "ID", text: "ID" },
        { value: "UB", text: "UB" },
        { value: "ID && UB", text: "ID && UB" },
        { value: "CORRESPONDENCE", text: "CORRESPONDENCE" },
        { value: "DECLINED", text: "DECLINED" },
        { value: "TRANSFER TO SUPERVISOR", text: "TRANSFER TO SUPERVISOR" },
        { value: "OTHERS", text: "OTHERS" },
      ],
      isLoading: false,
      files: "",
      namedoc: "",
      size: "",
      modalCall: this.modalCallRounds,
    };
  },
  created() {
    this.modalCall = true;
  },
  methods: {
    deleteAccount(id) {
      for (let i = 0; i < this.nameLeads.length; i++) {
        if (this.nameLeads[i].id == id) {
          this.nameLeads.splice(i, 1);
        }
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    onImageChange(e) {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(e.target.files[0]);

      fileReader.onload = (e) => {
        this.file = e.target.result;
      };
      const file = e.target.files[0].name;
      const { size } = e.target.files[0];
      this.namedoc = file;
      this.size = size;
    },
    async sendCallRounds() {
      const success = await this.$refs.form.validate();
      if (!success) {
      } else {
        try {
          const result = await this.showConfirmSwal();
          if (result.value) {
            this.addPreloader();
            const params = {
              task: this.task,
              description: this.description,
              image: this.file,
              namedoc: this.namedoc,
              size: this.size,
              nameaccount: this.nameLeads[0].nameAccount,
              nameprogram: this.nameLeads[0].nameProgram,
              account: this.nameLeads[0].id,
              user_id: this.currentUser.user_id,
            };

            const response = await ClientService.saveCallRound(params);
            if (response) {
              this.$emit("updateTable");
              this.$emit("validateStatus");
              this.showToast();
              this.closeModal();
              this.removePreloader();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    nuevo() {
      this.numeroAcco += 1;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
