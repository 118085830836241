export default [
  {
    title: "Personal Information",
    routeName: "financial-clients-account-personal-information",
    extActiveClass: "active border-radius-tabs",
    content: false,
  },
  {
    title: "Notes",
    routeName: "financial-task-notes",
    extActiveClass: "active border-radius-tabs",
    content: false,
  },
  {
    title: "Payments",
    routeName: "financial-clients-account-payments",
    extActiveClass: "active border-radius-tabs",
    content: false,
  },
  {
    title: "Files",
    routeName: "financial-clients-account-files",
    extActiveClass: "active border-radius-tabs",
    content: true,
  },
  {
    title: "Access Credentials",
    routeName: "financial-clients-account-access-credential",
    extActiveClass: "active border-radius-tabs",
    content: false,
  },
  // {
  //   title: "Services",
  //   routeName: "financial-client-services",
  //   extActiveClass: "active border-radius-tabs",
  //   content: false,
  // },
  {
    title: "Credit",
    routeName: "financial-clients-account-dispute",
    extActiveClass: "active border-radius-tabs",
    content: true,
  },
  // {
  //   title: "Report",
  //   routeName: "financial-clients-account-report",
  //   extActiveClass: "active border-radius-tabs",
  //   content: false,
  // },
  {
    title: "Call Log",
    routeName: "financial-clients-account-call-log",
    extActiveClass: "active border-radius-tabs",
    content: false,
  },
];
