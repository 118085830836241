import { amgApi } from "@/service/axios";

class EfectivityServices {
    async getCountEffectivity(params){
        try {
            const data = await amgApi.post('commons/get-count-effectivity', params )
            return data;
        } catch (error) {
            console.error(error)
        }
    }
}

export default new EfectivityServices();