<template>
  <b-modal
    v-model="onControl"
    title="Send Letter"
    title-tag="h3"
    modal-class="custom-modal-amg"
    size="lg"
    header-bg-variant="transparent"
    header-class="p-0"
    no-close-on-backdrop
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Send Letter
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <b-row>
        <b-col lg="6" class="mt-1">
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Client | {{ client.client_name }}
            </p>
          </div>
        </b-col>
        <b-col lg="6" class="mt-1">
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Account | {{ client.account }}
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="type_send"
            rules="required"
          >
            <b-form-group label="Select letter">
              <div class="d-flex flex-wrap ml-n1">
                <div
                  v-for="(item, index) in computedLetters"
                  :key="index"
                  class="ml-1"
                  style="margin-top: 0.4rem"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="type_send"
                    rules="required"
                  >
                    <b-form-radio v-model="selectedTypeLetter" :value="item.id"
                      ><b-img
                        width="20"
                        height="20"
                        :src="assetsImg + '/images/icons/pdf.png'"
                      />
                      <span
                        class="text-primary cursor-pointer"
                        style="margin-left: 0.3rem"
                        @click="generateLetter(item.id)"
                        >{{ item.name }}</span
                      >
                    </b-form-radio>
                    <span v-if="errors[0]" class="text-danger">
                      Letter {{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </div>
              <span v-if="errors[0]" class="text-danger">
                Type Send {{ errors[0] }}</span
              >
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-form-group label="Observation">
            <validation-provider
              v-slot="{ errors }"
              name="observation"
              rules="required"
            >
              <b-form-textarea
                v-model="observation"
                :state="errors[0] ? false : null"
              />
              <span v-if="errors[0]" class="text-danger">
                Observation {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <b-form-checkbox
        v-model="saveAsNote"
        checked="1"
        value="1"
        unchecked-value="0"
      >
        Save as Note
      </b-form-checkbox>
      <b-button 
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'" 
        @click="save">
        <feather-icon icon="SendIcon" />
        SEND
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex"
import ClientsDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services"
import LettersToClientsService from "@/views/correspondence/views/letters-to-clients/service/lettersToClients.service"

export default {
  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      onControl: false,
      saveAsNote: 0,
      letters: [
        { id: 1, label: "WELCOME LETTER", name: "WELCOME LETTER" },
        { id: 2, label: "WRONG NUMBER", name: "WRONG NUMBER" },
        { id: 5, label: "FIRST NOTICE", name: "FIRST NOTICE" },
        { id: 6, label: "SECOND NOTICE", name: "SECOND NOTICE" },
        { id: 7, label: "FINAL NOTICE", name: "FINAL NOTICE" },
        { id: 11, label: "QUARTERLY LETTER", name: "QUARTERLY LETTER" },
      ],
      lettersBc: [{ id: 2, label: "WRONG NUMBER", name: "WN" }],
      lettersEn: [
        { id: 3, label: "WELCOME LETTER", name: "WELCOME LETTER" },
        { id: 4, label: "WRONG NUMBER", name: "WRONG NUMBER" },
        { id: 8, label: "FIRST NOTICE", name: "FIRST NOTICE" },
        { id: 9, label: "SECOND NOTICE", name: "SECOND NOTICE" },
        { id: 10, label: "FINAL NOTICE", name: "FINAL NOTICE" },
        { id: 11, label: "QUARTERLY LETTER", name: "QUARTERLY LETTER" },
      ],
      lettersBcEn: [{ id: 4, label: "WRONG NUMBER", name: "WN" }],
      observation: "",
      selectedTypeLetter: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    computedLetters() {
      if (this.client.language == "ES") {
        if ([6, 20, 22].includes(this.moduleId)) {
          return this.letters
        }
        if (this.moduleId === 7) {
          return this.lettersBc
        }
      } else {
        if ([6, 20, 22].includes(this.moduleId)) {
          return this.lettersEn
        }
        if (this.moduleId === 7) {
          return this.lettersBcEn
        }
      }
      return ""
    },
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async save() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const data = await ClientsDashboardServices.sendLetterclient({
              letter: this.selectedTypeLetter.toString(),
              observation: this.observation,
              note: Number(this.saveAsNote),
              iduser: this.currentUser.user_id,
              accountid: this.$route.params.idClient,
              n_type: 3,
            })
            if (data.status === 200) {
              this.showSuccessSwal()
              this.close()
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async generateLetter(type) {
      try {
        this.addPreloader()
        let response = ""
        if ([6, 20, 22].includes(this.moduleId)) {
          response = await LettersToClientsService.openPdfLetterClient({
            accountid: this.$route.params.idClient,
            type,
          })
        } else if (this.moduleId === 7) {
          response = await LettersToClientsService.openPdfLetterClientBc({
            accountid: this.$route.params.idClient,
            type,
          })
        }

        if (response.status === 200) {
          await this.forceFileDownload(response.data, "document.pdf", "blank")
          this.removePreloader()
        }
      } catch (e) {
        console.log(e)
        this.removePreloader()
      }
    },
  },
}
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
  border: none !important;
}
</style>
