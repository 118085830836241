import { amgApi } from "@/service/axios";

class WorkPlansService {
  async getWorkPlanRequests(body) {
    const res = await amgApi.post('/credit-expert/connection/get-workplan-requests', body)

    return res.data;
  }
  async createWorkPlanRequest(body) {
    const res = await amgApi.post('/credit-expert/connection/create-workplan-request', body)

    return res.data;
  }
  async completeWorkPlanRequest(body) {
    const res = await amgApi.post('/credit-expert/connection/complete-workplan-request', body)

    return res.data;
  }
  async findClientAccountData(body) {
    const res = await amgApi.post('/credit-expert/connection/find-client-account', body)

    return res.data;
  }
}
export default new WorkPlansService();